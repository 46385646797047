import React from "react";
import { graphql, Link } from "gatsby";
import { format } from "date-fns";
import Img from "gatsby-image";

import { imageUrlFor } from "../lib/image-url";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

import clientConfig from "../../client-config";

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  buildImageObj,
  getBlogUrl,
  cn
} from "../lib/helpers";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  query BlogPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(limit: 6) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    external: allSanityExternalContent {
      nodes {
        id
        publishedAt
        mainImage {
          ...SanityImage
          alt
        }
        title
        externalName
        url
        _rawExcerpt
        excerpt {
          style
          list
        }
      }
    }
  }
`;

let Filters = ({ title, options, onOptionClick, active = -1, className, ...props }) => {
  return (
    <div className="pb-7">
      <h4 className="uppercase text-xs tracking-widest pb-4">{title}</h4>
      <ul className="flex flex-row lg:flex-col justify-center sm:justify-start">
        {options &&
          options.map((option, i) => {
            let activeClass =
              active === i ? "text-white border-white" : "text-lowWhite border-transparent";
            return (
              <li
                className={cn(
                  "font-display tracking-wide pb-1 lg:pb-0 lg:mb-2 lg:last:mb-0 mx-1 lg:mx-0 px-3 lg:px-0 text-sm lg:pl-2 lg:-ml-2 border-b-2 lg:border-b-0 lg:border-l-2 capitalize",
                  activeClass
                )}
                key={option.value}
                onClick={() => {
                  if (onOptionClick) onOptionClick(option, i);
                }}
              >
                {option.value}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
let ListItem = ({
  title,
  publishedAt,
  mainImage,
  _rawExcerpt,
  url,
  slug,
  externalName,
  ...props
}) => {
  let description = _rawExcerpt[0].children[0].text;
  const fluidProps = getFluidGatsbyImage(mainImage.asset._id, {}, clientConfig.sanity);
  fluidProps.aspectRatio = 1 / 0.606060606;
  let ContentLink = props => <a href={url} target="_blank" {...props} />;
  if (url == null || url.length === 0) {
    ContentLink = props => <Link to={getBlogUrl(publishedAt, slug.current)} {...props} />;
  }

  return (
    <div className="flex mb-8 sm:mb-4 flex-col sm:flex-row e-closeUp">
      <div className="w-full sm:w-6/12 md:w-5/12 lg:w-5/12 xl:w-4/12">
        <ContentLink className="block ">
          <Img
            fluid={fluidProps}
            alt={mainImage.alt}
            className="mb-1"
            caption={mainImage.caption}
          />
        </ContentLink>
      </div>
      <div className="w-full sm:w-6/12 md:w-7/12 lg:w-7/12 xl:w-8/12  sm:pl-3 lg:pl-5 pt-1">
        <ContentLink className="block ">
          <h1 className="text-xl font-regular sm:text-lg md:text-xl max-w-2xl mb-1">{title}</h1>
        </ContentLink>
        <h5 className="mb-1 text-xs leading-snug tracking-wider  font-display">
          {format(publishedAt, "MMM DD YYYY")}
          {externalName ? ` - Published in ${externalName}` : ""}
        </h5>
        <h1 className="text-sm text-lowWhite max-w-sm tracking-wide">{description}</h1>
      </div>
    </div>
  );
};
const BlogPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  let external = data.external.nodes;

  let contentTypes = [{ value: "all" }, { value: "WebGL" }, { value: "Projects" }];
  let sortingTypes = [{ value: "Curated List" }, { value: "Latest Articles" }];

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div className="pt-9 lg:bg-transparent">
        <div className="lg:pl-m-3/24 pb-8  text-left md:text-center lg:text-left">
          <h1 className=" font-display text-3xl xs:text-4xl  sm:text-5xl font-light mb-2 sm:mb-3  leading-tight">
            Articles
          </h1>
          <h3 className=" tracking-widest text-xs sm:text-base uppercase leading-snug  text-lowerWhite">
            Curation has priority over date sorting. Deal with it.
          </h3>
        </div>
        <div className="flex flex-col lg:flex-row justify-end">
          <div className="w-full lg:w-2/12 pr-1 flex flex-col sm:flex-row lg:flex-col">
            <div className="md:w-1/2 lg:w-auto">
              <Filters title="Content Type" active={0} options={contentTypes} />
            </div>
            <div className="md:w-1/2 lg:w-auto">
              <Filters title="Sorting" options={sortingTypes} active={1} />
            </div>
          </div>
          <div className="w-full  lg:w-9/12 flex flex-col justify-center items-center">
            <div className="w-10/12 sm:w-full md:w-11/12 lg:w-full lg:pl-1 ">
              {postNodes.map(item => (
                <ListItem {...item} key={item.id} />
              ))}
              {external.map(item => (
                <ListItem {...item} key={item.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogPage;
